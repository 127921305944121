<template>
    <div>
        <BT-Blade-Items
            actionsWidth="100"
            :items="newItems"
            canDelete
            :canRefresh="false"
            :canSelect="false"
            :loading="isLoading"
            :headers="[{ text: 'Company Name', value: 'companyName' },
                    { text: 'Email', value: 'email' },
                    { text: 'Phone Number', value: 'phoneNumber' },
                    { text: 'Address Line One', value: 'addressLineOne' },
                    { text: 'Street No', value: 'streetNumber' },
                    { text: 'Street Name', value: 'streetName' },
                    { text: 'Suburb', value: 'suburb' },
                    { text: 'State', value: 'state' },
                    { text: 'Postcode', value: 'postcode' }]"
            :hideFooter="false"
            :onEdit="editCustomer"
            :canCreate="false"
            :onDelete="deleteCustomer"
            title="New Clients">
            <template slot="actions">
                <BT-Dialog 
                    v-model="newCustomer" 
                    :loading="isLoading"
                    :show="showDialog"
                    label="New Client"
                    @ok="addOrUpdateCustomer"
                    @close="closeDialog"
                    width="450"
                    small
                    title="Add Client">
                    <template slot-scope="{ item }">
                        <v-text-field
                            label="Company Name"
                            v-model="item.companyName" />
                        <v-text-field
                            label="Email"
                            v-model="item.email" />
                        <v-text-field
                            label="Phone Number"
                            v-model="item.phoneNumber" />
                        <v-text-field
                            label="Address Line One"
                            v-model="item.addressLineOne" />
                        <v-text-field
                            label="Street Number"
                            v-model="item.streetNumber" />
                        <v-text-field
                            label="Street Name"
                            v-model="item.streetName" />
                        <v-text-field
                            label="Suburb"
                            v-model="item.suburb" />
                        <v-text-field
                            label="State"
                            v-model="item.state" />
                        <v-text-field
                            label="Postcode"
                            v-model="item.postcode" />
                        <BT-Select                
                            navigation="client-templates"
                            label="Agreement"
                            item-text="templateName"
                            itemValue="id"
                            v-model="item.defaultTemplateID" />
                        <v-text-field
                            label="Latitude"
                            v-model="item.lat" />
                        <v-text-field                        
                            label="Longitude"
                            v-model="item.lng" />                    
                        <v-btn
                            v-if="!item.lat || !item.lng"
                            class="warning"
                            @click="validate(item)">
                            <v-icon left>mdi-alert-outline</v-icon>Get Coordinates
                        </v-btn>
                        <GmapMap
                            ref="locMap"
                            :center="centerPosition"
                            :zoom="7"                    
                            style="width: 100%; height: 250px;"
                            :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }">                    
                        <gmap-marker v-if="position != null" :position="position" />
                    </GmapMap> 
                    </template>  
                    <template slot="actions" slot-scope="{ item }">
                        <v-btn v-if="item != null && item.lat && item.lng" text class="primary ml-auto" @click="save(item)">
                            <v-icon left>mdi-content-save</v-icon>Save
                        </v-btn>
                    </template>              
                </BT-Dialog>
                <BT-File-Select @fileSelected="importItems" title="Import CSV" />            
                
                <v-btn 
                    icon
                    small
                    title="Generate Example CSV File"
                    @click="generateCSVFile">
                    <v-icon small>mdi-file-delimited-outline</v-icon>
                </v-btn>
                
            </template>
            <template v-slot:actionsRight>

                <BT-Select-Dialog
                    v-if="isLengthyArray(itemsReadyToSave)"
                    canCancel
                    :text="`Save ${itemsReadyToSave.length} Valid Items`"
                    icon="mdi-content-save"
                    small
                    navigation="client-templates"
                    label="Agreement"
                    listItemText="templateName"
                    listItemValue="id"
                    @change="saveValidItems"
                    width="300" />

                <v-btn @click="validateAll" small>Validate All</v-btn>

            </template>
            <template slot="itemActions" slot-scope="{ item }">
                <v-icon 
                    v-if="customerExists(item.companyName)"
                    class="error--text"
                    small
                    title="Client already exists">mdi-alert</v-icon>
                <v-btn
                    icon
                    small>
                    <v-icon
                        v-if="item.lat == null || item.lng == null"
                        color="warning"
                        small
                        @click="editCustomer(item)">mdi-alert-outline</v-icon>
                </v-btn>
            </template>
        </BT-Blade-Items>
        <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        <v-alert v-model="showAlert" dismissible type="success">Saved</v-alert>     
        <BT-Snack v-model="msg" />
    </div>
    
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Client-New',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        BTFileSelect: () => import('~components/BT-File-Select.vue'),
    },
    data: function() {
        return {
            isLoading: false,
            showDialog: false,
            newCustomer: { 
                companyName: null,
                email: null,
                phoneNumber: null,
                addressLineOne: null,
                streetNumber: null,
                streetName: null,
                suburb: null,
                state: null,
                postcode: null,
                lat: null,
                lng: null 
            },
            newItems: [],
            showError: false,
            showAlert: false,
            errorMessage: null,
            position: null,    
            msg: null,
            existingCustomers: []
        }
    },
    async mounted() {
        if (this.$route.params && this.$route.params.customers) {
            this.newItems = this.$route.params.customers;
        }

        this.existingCustomers = await this.$BlitzIt.store.getAll('clients', { properties: 'ID,Buyer' });
    },
    computed: {
        google: getGoogleMapsAPI,
        centerPosition() {
            return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        itemsReadyToSave() {
            return this.newItems.filter(y => y.lat != null && y.lng != null);
        }
    },
    methods: {
        customerExists(companyName) {
            return this.existingCustomers.some(x => x.buyer.companyName == companyName);
        },
        repositionMarker(location) {
            if (location.lat != null && location.lng != null) {
                this.position = {
                    lat: location.lat,
                    lng: location.lng
                }
            }
            else {
                this.position = null;
                this.isChanged = true;
            }
        },
        async validateAll() {
            console.log('validating all');
            try {
                this.isLoading = true;
                this.$forceUpdate();

                //var coder = null; await new this.google.maps.Geocoder();
                //console.log(coder);

                for (let i = 0; i < this.newItems.length; i++) {
                    const newItem = this.newItems[i];
                    if (!this.customerExists(newItem.companyName)) {
                        if (newItem.lat == null && newItem.lng == null) {
                            var req = {
                                address: getLocationLine(newItem, true),
                            };

                            var results = await new this.google.maps.Geocoder().geocode(req);
                            if (results.results.length == 1) {
                                newItem.lat = results.results[0].geometry.location.lat();
                                newItem.lng = results.results[0].geometry.location.lng();
                            }
                            else {
                                console.log(results);
                            }
                        }
                    }
                }
            }
            catch (err) {
                console.log(err);
                this.errorMessage = this.extractErrorDescription(err);
                this.showError = true;
            }
            finally {
                this.isLoading = false;
            }
        },
        async validate(item) {
            if (this.customerExists(item.companyName)) {
                return;
            }
            this.isLoading = true;
            var add = getLocationLine(item, true);

            var request = {
                address: add
            };

            var self = this;

            new this.google.maps.Geocoder().geocode(request, (results, status) => {
                if (status === 'OK') {
                  //proceed                         
                    if (results.length > 1) {
                        self.errorMessage = "Multiple Options Found";
                        self.showError = true;
                        //self.formError("Multiple Options Found");                        
                    } 
                    else if (results.length == 1) {
                        item.lat = results[0].geometry.location.lat(); 
                        item.lng = results[0].geometry.location.lng();                                       
                        self.repositionMarker(item);
                    } 
                    else {
                        self.errorMessage = "Address Not Found";
                        self.showError = true;
                    }           
                }
                self.isLoading = false;
            })      
        },
        deleteCustomer(cust) {
            var ind = this.newItems.findIndex(y => y.companyName == cust.companyName);
            if (ind >= 0) {
                this.newItems.splice(ind, 1);
            }
        },
        closeDialog() {
            this.showDialog = false;
        },
        generateCSVFile() {
                var file = new Blob(['Company Name,Address Line One,Street Number,Street Name, Suburb,State,Postcode,Email,Phone Number'], { type: "text/plain;charset=utf-8" });
                var fileName = 'customerImport.csv';
                if (window.navigator.msSaveOrOpenBlob) {                    
                    window.navigator.msSaveOrOpenBlob(file, fileName);
                }
                else {                    
                    var a = document.createElement("a"),
                        url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
        },        
        editCustomer(item) {
            this.newCustomer = item;
            this.showDialog = true;
            this.repositionMarker(item);                                      
        },
        addOrUpdateCustomer(item) {            
            this.showDialog = false;

            if (!this.newItems.some(x => x.companyName == item.companyName)) {                
                this.newItems.push(item);
            }
            else {
                var ind = this.newItems.findIndex(x => x.companyName == item.companyName);                

                this.newItems[ind] = item;
            }

            this.newCustomer = {
                companyName: null,
                email: null,
                phoneNumber: null,
                addressLineOne: null,
                streetNumber: null,
                streetName: null,
                suburb: null,
                state: null,
                postcode: null,
                lat: null,
                lng: null
            }
        },
        importItems(file) {            
                var reader = new FileReader();
                
                reader.onload = (e) => {
                    var txt = e.target.result;
                    if (txt.length) {
                        //var itemStrings = txt.split(' /\r?\n/');

                        var items = txt.split('\n');                        
                        var missed = 0;
                        var added = 0;
                        var failed = 0;

                        for (var i = 0; i < items.length; i++) {
                            var nItem = {
                                companyName: null,
                                addressLineOne: null,
                                streetNumber: null,
                                streetName: null,
                                suburb: null,
                                state: null,
                                postcode: null,
                                email: null,
                                phoneNumber: null,
                                lat: null,
                                lng: null                                
                            };

                            var nItemProps = items[i].split(',');

                            if (nItemProps.length > 0) {
                                nItem.companyName = nItemProps[0];
                            }
                            if (nItemProps.length > 1) {
                                nItem.addressLineOne = nItemProps[1];
                            }
                            if (nItemProps.length > 2) {
                                nItem.streetNumber = nItemProps[2];
                            }
                            if (nItemProps.length > 3) {
                                nItem.streetName = nItemProps[3];
                            }
                            if (nItemProps.length > 4) {
                                nItem.suburb = nItemProps[4];
                            }
                            if (nItemProps.length > 5) {
                                nItem.state = nItemProps[5];
                            }
                            if (nItemProps.length > 6) {
                                nItem.postcode = nItemProps[6];
                            }
                            if (nItemProps.length > 7) {
                                nItem.email = nItemProps[7];
                            }
                            if (nItemProps.length > 8) {
                                nItem.phoneNumber = nItemProps[8];
                            }

                            if (nItem.companyName != null && nItem.companyName.length > 0 && nItem.companyName != 'Company Name') {
                                if (nItem.companyName == null) {
                                    failed++;
                                }
                                else {
                                    var found = false;
                                    for (var ii = 0; ii < this.newItems.length; ii++) {
                                        if (this.newItems[ii].companyName === nItem.companyName) {
                                            missed++;
                                            found = true;
                                        }
                                    }
                                    if (!found) {
                                        if (!this.customerExists(nItem.companyName)) {
                                            this.newItems.unshift(nItem);
                                            added++;
                                        }
                                    }
                                }                            
                            }                            
                        }
                        this.errMsg = added + ' added. ' + missed + ' missed. ' + failed + ' failed.';
                    }
                };

                reader.readAsText(file);

        },
        async saveValidItems(templateID) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                var itemsToSave = this.newItems.filter(x => x.lat != null && x.lng != null);

                for (let i = 0; i < itemsToSave.length; i++) {
                    const itemToSave = itemsToSave[i];
                    
                    if (itemToSave.defaultTemplateID == null) {
                        itemToSave.defaultTemplateID = templateID;
                    }

                    var data = {
                        subscriptionCode: 'CLIENTFREE',
                        companies: [itemToSave]
                    };

                    await this.$BlitzIt.api.post('new-clients', data, null, '/PostClients');

                    var ind = this.newItems.findIndex(y => y.companyName == itemToSave.companyName);
                    if (ind >= 0) {
                        this.newItems.splice(ind, 1);
                    }
                }
            }
            catch (err) {
                this.errorMessage = this.extractErrorDescription(err);
                this.showError = true;
            }
            finally {
                this.isLoading = false;
            }
        },
        save(item) {
            var self = this;

            self.isLoading = true;

             var data = {
                subscriptionCode: 'ClientFREE',
                companies: [item]
            };

            this.$BlitzIt.api.post('new-clients', data, null, '/PostClients')
                .then(() => {
                    var ind = self.newItems.findIndex(y => y.companyName == item.companyName);
                    if (ind >= 0) {
                        self.newItems.splice(ind, 1);
                    }
                    
                    self.showDialog = false;
                    self.msg = `${item.companyName} created`;
                })
                .catch(err => {
                    self.errorMessage = self.extractErrorDescription(err);
                    self.showError = true;
                })
                .finally(() => { 
                    self.isLoading = false;
                    self.showDialog = false;
                    self.newCustomer = {
                        companyName: null,
                        email: null,
                        phoneNumber: null,
                        addressLineOne: null,
                        streetNumber: null,
                        streetName: null,
                        suburb: null,
                        state: null,
                        postcode: null,
                        lat: null,
                        lng: null
                    }
                })
        }
    }
}
</script>